import React from "react"
import StyledSidebar from "./Sidebar.style"
import SubHeading from "../../atoms/SubHeading/SubHeading"
import { randomID } from "../../particles/helpers"
import { Link } from "gatsby"
import { useBlogData } from "../../particles/hooks/useBlogData"

const Sidebar = () => {
  const recentBlogs = useBlogData()

  return (
    <StyledSidebar>
      <SubHeading title="Our Blog" />
      {recentBlogs.map(post => (
        <article key={randomID()}>
          <Link to={`/blogs${post.uri}`}>
            <h4>{post.title}</h4>
          </Link>
          <p className="underline">
            {post.author.node.firstName} {post.author.node.lastName}
          </p>
          <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
          <Link to={`/blogs${post.uri}`} className="learn-more">
            Learn More...
          </Link>
        </article>
      ))}
    </StyledSidebar>
  )
}

export default Sidebar
