import React from "react"
import { Link } from "gatsby"
import StyledButton from "./SecondaryButton.style"
import { isInternal } from "../../../particles/helpers"
import useRelative from "../../../particles/hooks/useRelative"

const SecondaryButton = ({
  children,
  url,
  className,
  color,
  dark,
}: ButtonProps) => {
  if (isInternal(url)) {
    return (
      <Link
        to={useRelative(url)}
        css={StyledButton}
        className={(dark ? `dark ` : `light `) + className}
      >
        {children}
      </Link>
    )
  } else {
    return (
      <a
        href={url}
        css={StyledButton}
        className={(dark ? `dark ` : `light `) + className}
        target="_blank"
      >
        {children}
      </a>
    )
  }
}

type ButtonProps = {
  children: string
  url: string
  className?: string
  color?: string
  dark?: boolean
}

export default SecondaryButton
